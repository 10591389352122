











































































































.projectnav {
  display: flex;
  gap: 1rem;
  height: 3rem;
  width: 100vw;
  left: 0;
  cursor: auto;
}
.arrow {
  display: inline-block;
  vertical-align: middle;
}
.arrow svg{
  position: relative;
  top: 2px;
  padding-right: 8px;
}
.arrow.next svg {
  padding-left: 8px;
}
.projectnav.transparent.white {
  color: #fff;
  .arrow line, .arrow path {
    stroke: #fff;
  }
}
.news-link{
  cursor: pointer;
  padding-right: 2rem;
}
.save { text-decoration: none; }
/*.save:after {
  content: url(/assets/_pic/download-icon.svg);
  display: inline-block;
  padding-left: 0.3rem;
  transform: translateY(0.2rem);
  transform-origin: 0 100%;
  height: 1.1rem;
  width: 1.1rem;
}*/
@media(max-width: 699px) {
  .save { min-width: 65px; text-align: right; }
}
@media(min-width: 700px) {
  .projectnav * {
    text-transform: uppercase;
  }
  .projectnav { cursor: auto; }
  .arrow { cursor: pointer; }
  .arrow svg{
    top: 0px;
  }
  .prefix { padding-right: 7px; }
  .pagenav { padding-left: 2rem; padding-right: 2rem; }
}
@media(max-width: 699px) {
  .projectnav {
    position: fixed;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 0 1.2rem;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 4fr 1fr 4fr;
    z-index: 200;
    .news-link {
      justify-self: center;
      padding-right: 0;
    }
    &.transparent {
      background: transparent;
    }
    .numbers {
      display: inline-block;
      vertical-align: middle;
    }
  }
}
