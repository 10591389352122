


















.close {
  display: flex;
  justify-content: center;
  cursor: pointer;
  svg {
    width: 22px;
    height: 22px;
  }
}
