

















































.news {
  position: relative; z-index: 1000; cursor: auto;
}
@media(min-width: 700px) {
  .news-container {
    display: contents;
    height: auto !important;
  }
  .news {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    width: 77%;
    height: 86%;
    align-self: end;
    //default color
    background-color: var(--hbf-yellow);
    display: grid;
    margin-bottom: -1rem;
    overflow-y: auto;
    top: auto !important;
    .news-header {
      margin-bottom: 1rem;
      display: grid;
      grid-template-columns: 1fr 40px;
      white-space: pre-line;
    }
    .news-text {
      align-self: end;
    }
  }
}
@media(max-width: 699px) {
  .news-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    z-index: 800;
  }
  .news {
    height: calc(50% - 3rem);
    position:absolute;
    left: 0;
    bottom: 0;
    padding: 1rem;
    margin-left: 0;
    overflow: auto;
    z-index: 800;
    width: calc(100% - 2rem);
    padding-bottom: 2rem;
    .news-header {
      font-size: 1.5rem;
      line-height: 1.2;
      margin-bottom: 2rem;
      padding-right: 3rem;
    }
    .news-text {
      font-size: 1rem;
    }
    .close {
      position: absolute;
      right: calc(1rem - 2px);
      top: .5rem;
    }
  }
  //for home slider
  .home-slider .news {
    bottom: auto;
    top: 50vh;
    height: auto;
    z-index: 1200;
  }
  .project-contents .news-container {
    z-index: 1200;
  }
}
