



















































.cookie-warning {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  text-transform: uppercase;
  z-index: 2000;
}
.ok-btn {
  text-decoration: underline;
  cursor: pointer;
}
