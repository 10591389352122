







































































































































































































































































































































//not sure if working
@import url("https://api.mapbox.com/mapbox-gl-js/v1.12.0/mapbox-gl.css");
::v-deep .directions {
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  font-size: 15px;
  overflow-y: auto;
  border: 1px solid #000;
  padding: 0.5rem;
  z-index: 200;
  max-width: calc(100vw - 5rem);
  cursor: auto;
  a {
    text-decoration: none;
  }
  @media (min-width: 700px) {
    width: 25%;
  }
  @media (max-width: 699px) {
    //bottom: 4.2rem;
    //left: .75rem;
    //max-width: calc(100vw - 2.5rem);
    max-width: 100%;
    width: calc(100vw - 1rem);
    left: 0.5rem;
    top: 0;
    bottom: auto;
    height: 100%;
    box-sizing: border-box;
    border: none;
    padding-top: 1.2rem;
    padding-right: 0.75rem;
    .directions-text {
      padding-top: 1rem;
    }
  }
}
::v-deep .mgl-map-wrapper {
  height: 100%;
}
::v-deep .mapboxgl-map {
  height: 100%;
}
::v-deep .mapboxgl-ctrl-attrib {
  display: none;
}
::v-deep .mgl-map-wrapper {
  &:focus {
    outline: none;
  }
}
::v-deep .mgl-map-wrapper * {
  &:focus {
    outline: none;
  }
}
::v-deep .mapboxgl-popup {
  font-family: Favorit, sans-serif;
  line-height: 1.3;
  font-weight: normal;
  color: #000;
  font-size: 15px;
  cursor: auto;
  .mapboxgl-popup-content {
    padding: 0;
    background: none;
    box-shadow: none;
  }
  .mapboxgl-popup-tip {
    display: none;
  }
  .hbf-name {
    text-transform: uppercase;
    padding-bottom: 1em;
  }
  .popup-inside {
    padding: 0.5em;
    border: 1px solid #000;
    position: relative;
    top: -40px;
    left: -22px;
  }
}
::v-deep .mapboxgl-ctrl-logo {
  display: none;
}
::v-deep .mapboxgl-ctrl-group {
  border-radius: 0;
  display: none;
}
//create a fake tip with the right Color
.fake-tip {
  position: absolute;
  bottom: -44px;
  z-index: 0;
}
/*.toggle-directions {
  position: absolute;
  bottom: 1rem;
  left: 0.75rem;
  border: 1px solid #000;
  padding: 1rem;
}*/
.toggle-directions {
  margin-top: 1rem;
  position: relative;
  z-index: 9999999;
}
.map-page-nav {
  display: none;
}
@media (min-width: 700px) {
  ::v-deep .mgl-map-wrapper {
    grid-column: 1 / 3;
  }
  .page.contact.page-0 {
    grid-column: 1 / 3;
    justify-self: center;
    grid-template-columns: 1fr;
  }
  .toggle-directions {
    display: none;
  }
  .close {
    display: none;
  }
  .map-page-nav {
    position: absolute;
    display: block;
    bottom: 2rem;
    right: 2rem;
    border: 1px solid #000;
    padding: 0.5rem;
    padding-bottom: 0;
    cursor: pointer;
    svg {
      padding: 0.25rem;
    }
  }
}
