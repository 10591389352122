


















































// a { color: blue; }
.project-header {
  line-height: 1.1;
  @media(min-width: 700px) {
    grid-column: 1 / 3;
    h1 {
      max-width: 50%;
    }
  }
  display: grid;
  grid-template-columns: 1fr 40px;
}
