



























































































































.site-header {
  display: grid;
  grid-template-columns: calc(50vw - 1rem) 1fr 40px;
  height: 6rem;
  cursor: auto;
  @media(min-width: 700px) {
    //height: 5rem;
    grid-column: 1 / 3;
  }
}
.page-title {
  margin-left: var(--standard-indent);
  line-height: 1.1;
  &.small {
    text-transform: uppercase;
  }
  &.big {
    font-size: 2rem;
    margin-left: 0;
    @media(min-width: 700px) {
      max-width: 90%;
    }
  }
  a {
    text-decoration: none;
    color: inherit;
    /*color: #fff;
    mix-blend-mode: difference;
    filter: grayscale(100%);*/
  }
}
.secondary-infos {
  z-index: 500;
}
.site-name{
  margin-left: var(--standard-indent);
  z-index: 500;
  .menushowing & {
    color: #000;
  }
  a { text-decoration: none; }
}
.site-name, .edit-link {
  display: inline;
}
.edit-link { padding-left: 2rem; }
.menu-button-and-close {
  z-index: 500;
}
.menu-button{
  width: 39px;
  align-self: start;
  cursor: pointer;
  hr {
    border: 0;
    border-top: 1px solid #000;
    &:first-of-type { margin-top: 0; }
  }
}
.site-header {
  //position: relative;
  z-index: 900;
}
@media(min-width: 700px) {
  .desktop-nav {
    display: contents;
  }
  .secondary-infos {
    display: flex;
  }
}
@media(max-width: 699px) {
  .site-header {
    height: 7rem;
    .secondary-infos {
      display: none;
    }
    .home-slider & {
      position: fixed;
      width: calc(100vw);
      left: 0;
      top: 0;
      padding-top: 1rem;
      padding-left: 1.2rem;
      height: 7rem;
      box-sizing: border-box;
      padding-right: 1.2rem;
      .page-title, .secondary-infos, .menu-button-and-close {
        height: 6rem;
      }
      &.menushowing {
        height: 100%;
      }
      .secondary-infos { display: block; }
    }
    .menu-button-and-close {
      grid-column: 3 / 4;
    }
    .page-title {
      grid-column: 1 / 3;
      &.big {
        font-size: 1.85rem;
      }
    }
  }
  .site-header.menushowing {
    .page-title {
      display: none;
    }
    .secondary-infos {
      display: block;
    }
  }
}
