























.page-title {
  text-decoration: underline;
}
img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media(min-width: 700px) {
  .organigram-and-title {
    grid-column: 1/3;
    display: grid;
    grid-row-gap: 1rem;
    height: 100%;
    //not very happy to have to set the title at only 1rem, but I didn't see otherwise how to get it to not take up half the block.
    grid-template-rows: 1rem 1fr;
  }
}
