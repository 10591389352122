






























.col-title:not(.empty) {
  text-decoration: underline;
}
@media(min-width: 700px) {
  .project-texts {
    height: 100%;
    //need to deal with overflow here.
    min-height: 40vw;
  }
  .textpage {
    overflow-y: auto;
  }
}
@media(min-width: 1000px) {
  .project-texts {
    display: contents;
    /*grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;*/
  }
}
@media(max-width: 699px) {
  .col { margin-bottom: 1rem; }
}
