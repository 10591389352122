





















































































































































































































































































































.buero {
  //cover the whole screen with the same grid as projects?
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  z-index: 900;
  display: grid;
  position: fixed;
  width: calc(100% - 2.4rem);
  height: calc(100% - 2rem);
  margin: 1rem 1.2rem 1rem 1.2rem;
  z-index: 1000;
  @media(min-width: 700px) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: .25rem;
    grid-template-rows: 6em calc(100vh - 6rem - 1.5rem);
    margin-bottom: 0;
  }
  @media(max-width: 699px) {
    display: block;
    margin: 0;
    width: 100%;
    height: 100% !important;
    overflow-y: auto;
    .close {
      position: absolute;
      top: 0.75rem;
      right: 1rem;
      //background-color: var(--hbf-yellow);
      z-index: 100;
      padding: 0.2rem;
    }
  }
}
::v-deep .page.textpage, .page.team, .page.list {
  @media(max-width: 699px) {
    padding-bottom: 4rem;
  }
}
.page {
  background: var(--hbf-yellow);
  @media(min-width: 700px) {
    grid-row: 2 / 3;
    justify-self: end;
    width: 74%;
    height: 86%;
    margin-bottom: 0;
    position: relative;
    bottom: -.25rem;
    padding: 1rem;
    overflow: auto;
    //unclear why here more.
    margin-left: -1rem;
    &.page-0 {
      grid-column: 1 / 2;
      margin-right: calc(-1rem - 1px);
      align-self: end;
    }
    &.page-1 {
      grid-column: 2 / 3;
      align-self: end;
      display: grid;
      justify-self: start;
    }
  }
  @media(max-width: 699px) {
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    padding: 3rem .75rem 1rem .75rem;
  }
}
@media(max-width: 699px) {
  .buero-mobile-label {
    position: absolute;
    top: 1rem;
    left: .75rem;
    //padding-left: calc(var(--standard-indent) + 0.5rem);
    font-size: 0.85rem;
  }
  .buero .close-buero {
    z-index: 200;
    position: fixed;
  }
}
