























































































































































































































































































































img,
video {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1000;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.project-link {
  margin-left: var(--standard-indent);
  text-transform: uppercase;
  a {
    text-decoration: none;
    color: #000;
    /*color: #fff;
    mix-blend-mode: difference;*/
    //filter: grayscale(100%);
  }
}
@media (min-width: 700px) {
  .home-slider {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 0.25rem;
    grid-template-rows: 6em calc(100vh - 6rem - 2.5rem);
  }
  .home-slider,
  .home-element {
    height: 1px;
  } //doesnt really matter but gets rid of overflow in ipad.
}
@media (max-width: 699px) {
  .home-slider {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 0.25rem;
    grid-template-rows: 7em calc(100vh - 7rem - 2.5rem);
    img {
      position: fixed;
    }
  }
  .mobile-home-title {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
  }
  .mobile-title {
    font-size: 3rem;
    line-height: 1;
    margin-top: 7rem;
    padding: 1rem;
    a {
      text-decoration: none;
    }
  }
  .allprojects {
    padding: 1rem;
    padding-left: var(--standard-indent);
    text-transform: uppercase;
    position: absolute;
    bottom: 20px;
    text-decoration: none;
  }
  .arrow-right {
    width: 1.3rem;
    position: relative;
    top: 0.27rem;
    margin-left: 0.5rem;
  }
}
