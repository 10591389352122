

































.last-page {
  display: grid;
}
.menu-footer {
  align-self: end;
  padding-bottom: 1rem;
  text-transform: uppercase;
  a {
    text-decoration: none;
  }
  display: flex;
  grid-column: 1 / 3;
  grid-gap: 2rem;
  font-size: 0.85rem;
  .col:nth-of-type(2) {
    min-width: 10em;
  }
}
@media (min-width: 700px) {
}
