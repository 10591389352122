




















































































































































































































































































































































































































// a { color: blue; }
.transition-div, .spread-container {
  display: contents;
}
.project-contents {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
}
@media (min-width: 700px) {
  .project-contents {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: .25rem;
    grid-template-rows: 6em calc(100vh - 6rem - 2.5rem);
  }
  .page:not(.fullbleed) {
    overflow-y: auto;
    overflow-x: hidden;
  }
}
@media(max-width: 699px) {
  .project-contents {
    grid-template-rows: 7rem calc(100vh - 7rem - 5rem) 5rem;
    //height: 100vh; //TO DO: RESPONSIVE SAFARI IOS AHH
  }
}
