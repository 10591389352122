


























































































































.name, .role, .bio {
  display: inline;
}
img {
  filter: grayscale(100%);
  mix-blend-mode: multiply;
  opacity: 0.9;
}
img:hover {
  cursor: pointer;
}
.text-normal {
  margin-bottom: 1rem;
  ::v-deep u {
    text-transform: uppercase;
  }
}
.text-small {
  font-size: 0.7rem;
  ::v-deep u {
    text-transform: uppercase;
  }
}
.email, .phone {
  white-space: nowrap;
}
@media(min-width: 700px) {
  img.enlarged {
    position: absolute;
    //left: 1rem;
    left: 2rem;
    z-index: 9999;
    //top: 1rem;
    top: auto;
    bottom: -1px;
    filter: none;
    mix-blend-mode: normal;
    max-width: calc(50% - 2rem);
    //max-height: calc(100% - 2rem);
    max-height: 75%;
    opacity: 1;
    object-fit: contain;
    object-position: bottom right;
    &.right {
      left: auto;
      //right: 1rem;
      right: 1.75rem;
      object-position: bottom left;
    }
  }
  .page.team.page-0 {
    grid-column: 1 / 3;
    justify-self: center;
    display: block;
    /*grid-template-columns: 1fr;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;*/
  }
  .team-container {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  .overflow-container {
    column-count: 4;
    column-fill: auto;
    overflow: hidden;
    height: 100%;
    column-gap: 40px;
  }
  .person {
    //width: 25%;
    widows: 3;
    orphans: 4;
    clear: both;
    padding-bottom: 1em;
  }
  img {
    width: 50%;
    max-width: 150px;
    margin-right: .25em;
    height: auto;
    float: left;
    position: relative;
    top: .25em;
    margin-bottom: .1em;
    filter: grayscale(100%);
    mix-blend-mode: multiply;
  }
}
@media(max-width: 699px) {
  img {
    float: left;
    height: 14.8em;
    width: auto;
    margin-right: .4rem;
    margin-bottom: 0;
  }
  .person {
    clear: both;
    margin-bottom: 1rem;
  }
  img.enlarged {
    position: fixed;
    left: 0.75rem;
    z-index: 9999;
    top: 3rem;
    filter: none;
    mix-blend-mode: normal;
    height: auto;
    max-width: calc(100% - 1.75rem);
    max-height: calc(100% - 2rem);
    opacity: 1;
    object-fit: contain;
  }
}
