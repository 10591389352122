


































































.page-title {
  text-decoration: underline;
  //grid-column: 1 / 3;
  margin-bottom: 1rem;
}
/*@media(min-width: 700px) {
  .page.projectlist.page-0 {
    grid-column: 1 / 3;
    justify-self: center;
    grid-template-columns: 1fr;
    display: block;
  }
  .projectlist-container {
    width: 100%;
    height: 100%;
    overflow:scroll;
  }
  .overflow-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    //width: 100%;
    height: 100%;
    overflow:scroll;
  }
  .project {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
  }*/

  //this version uses css columns
  .page-title {
    text-decoration: underline;
    //grid-column: 1 / 3;
    margin-bottom: 1rem;
  }
  @media(min-width: 700px) {
    .page.projectlist.page-0 {
      grid-column: 1 / 3;
      justify-self: center;
      grid-template-columns: 1fr;
    }
    .overflow-container {
      display: block;
      column-count: 2;
      column-fill: auto;
      column-gap: 2rem;
    }
    .overflow-container, .projectlist-container {
      overflow: hidden;
      width: 100%;
      height: 100%;
    }
    .project {
      display: grid;
      grid-template-columns: 1fr 5fr 1fr;
      grid-column-gap: 1rem;
    }


}
