@font-face {
  font-family: "Favorit";
  src: url("/assets/fonts/FavoritStd-Regular.woff2") format("woff2"),
       url("/assets/fonts/FavoritStd-Regular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Favorit";
  src: url("/assets/fonts/FavoritStd-Bold.woff2") format("woff2"),
       url("/assets/fonts/FavoritStd-Bold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Favorit";
  src: url("/assets/fonts/FavoritStd-Italic.woff2") format("woff2"),
       url("/assets/fonts/FavoritStd-Italic.woff") format("woff");
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: "Favorit";
  src: url("/assets/fonts/FavoritStd-BoldItalic.woff2") format("woff2"),
       url("/assets/fonts/FavoritStd-BoldItalic.woff") format("woff");
  font-style: italic;
  font-weight: 700;
}
