




































































































































































































































































































































































































.thumb-label {
  font-size: 0.6rem;
}
.thumb-label.hasthumb {
  position: relative;
  top: -2.2rem;
  height: 2rem;
  display: inline-grid;
  align-items: end;
  overflow: hidden;
}
.label-inside {
  display: inline;
  position: relative;
}
.label-inside-inside {
  display: inline;
  background-color: #fff;
  padding-right: 2px;
}
.project-thumb {
}
.reset {
  padding-bottom: 0.1rem;
  &.active,
  &:hover {
    border-bottom: 1px solid #000;
  }
}
.filter {
  text-transform: uppercase;
  margin-bottom: 2rem;
}
.reset,
.filter-by,
.active-filter {
  cursor: pointer;
}
.project-thumb,
.project-list-item {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.reset,
.filter-by,
.active-filter {
  display: inline-block;
  margin-right: 20px;
}
.active-filter {
  margin-right: 0;
}
@media (min-width: 700px) {
  .thumbs-container,
  .list-container {
    overflow-y: auto;
    height: calc(100vh - 5rem - 2.5rem - 3.5rem);
  }
  .project-list {
    min-height: 100%; //balances columns. only works if height is 100%...
  }
  .project-thumbs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 0rem;
    .project-thumb {
      position: relative;
      width: 100%;
      img {
        width: 100%;
        filter: grayscale(100%);
      }
    }
  }
  //some funny margins to override main margins for poeple who look at their scrollbars ugh
  .results {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
    margin-right: -1.2rem;
  }
  .thumbs-container {
    margin-right: -0.5rem;
    padding-right: 1rem;
  }
}
@media (min-width: 1200px) {
  .project-list {
    column-count: 2;
    column-fill: auto;
    height: 100%;
    min-height: 50rem;
  }
}
@media (max-width: 699px) {
  .thumbs-container {
    display: none;
  }
  .reset {
    //padding-left: 4px;
    font-size: 16px;
  }
  .project-list {
    //border-top: 1px solid #000;
  }
  .project-list-item {
    border-bottom: 1px solid #000;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .reset {
    &.active,
    &:hover {
      //border-bottom: 0;
    }
  }
  .reset,
  .filter-by,
  .active-filter {
    display: block;
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
  transition-delay: 0.1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
@media (max-width: 1800px) {
  .select-status-desktop {
    display: none;
  }
}
@media (min-width: 1800px) {
  .select-status-mobile {
    display: none;
  }
}
@media (min-width: 700px) {
  input[type="radio"] {
    opacity: 0;
  }
}
label.active {
  padding-bottom: 0.1rem;
  border-bottom: 1px solid #000;
}
