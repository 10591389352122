

























































































































.buero-nav {
  @media(min-width: 700px) {
    z-index: 1;
    grid-column: 1 / 3;
    width: 74%;
    top: calc(100% - 86% + 1.25rem + 5px);
    position: absolute;
    justify-self: center;
    padding-left: calc(1rem + 0.3rem); //dont understand these 4px
    padding-right: calc(1rem + 0.3rem); //dont understand these 4px
    padding-top: 1rem;
    padding-bottom: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
    &.first {
      width: 37%;
      justify-self: center;
      margin-right: calc(-37% - 1rem - 0.29rem);
      padding-left: 0;
      grid-template-columns: 1fr;
      .buero-nav-list {
        padding-left: 1rem;
      }
      .buero-title {
        display: none;
      }
    }
    &.end {
      width: 37%;
      justify-self: center;
      margin-right: calc(37% + 1rem + 0.23rem);
      padding-right: 0;
      .buero-nav-list {
        display: none;
      }
    }
    ul {
      display: inline-block;
    }
    li {
      display: inline-block;
      padding-left: 0;
      padding-right: 10px;
      position: relative;
      z-index: 999999;
      &.menu-item {
      }
      &.active {
        text-decoration: underline;
      }
    }
    li:hover span{
      text-decoration: underline;
    }
    li:before {
      border-top: 0;
      position: static;
      display: none;
    }
    .selectlist {
      position: absolute;
      top: 0;
      opacity: 0;
    }
    .mobile {
      display: none;
    }
  }
  //special for tablets?
  @media (min-width: 730px) and (max-width: 1100px) {
    top: calc(100% - 86% + 1.25rem + 6px);
  }
  @media(max-width: 699px) {
    position: fixed;
    z-index: 100;
    .desktop {
      display: none;
    }
    .mobile {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      width: 100vw;
    }
    select {
      padding-left: 0.75rem;
      width: auto;
      display: inline;
      background-color: transparent;
      border-bottom: 0;
    }

  }
  .buero-title {
    text-transform: uppercase;
    cursor: pointer;
  }
  .down-arrow {
    padding-left: 5px;
  }
}
.mobile .save { padding-left: 20px; text-transform: uppercase; font-size: 16px; }
