


























































































.name, .role, .bio {
  display: inline;
}
::v-deep .listheader {
  //column-span: all;
  text-decoration: underline;
}
@media(min-width: 700px) {
  .page.list.page-0 {
    grid-column: 1 / 3;
    justify-self: center;
    display: block;
    /*grid-template-columns: 1fr;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;*/
  }
  .list-container {
    overflow: hidden  ;
    width: 100%;
    height: 100%;
  }
  .overflow-container {
    column-count: 4;
    column-fill: auto;
    overflow: hidden;
    height: 100%;
    column-gap: 40px;
    widows: 3;
    orphans: 4;
  }
  .list {
    break-before: column;
  }
  .person {
    //width: 25%;
    widows: 3;
    orphans: 4;
    clear: both;
    padding-bottom: 1em;
  }
}
@media(max-width: 699px) {
  .list {
    padding-top: 4rem;
  }
  .list:first-of-type {
    padding-top: 0.5rem;
  }
}
