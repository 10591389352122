

































h1 {
  text-transform: uppercase;
}
li {
  padding-left: 0;
  font-size: 2rem;
  text-transform: uppercase;
  cursor: pointer;
  &:hover { text-decoration: underline; }
  &:before { border: 0; width: 0; }
}
@media(min-width: 700px) {
  .page.page-0.front-page {
    grid-column: 2 / 3;
    justify-self: start;
  }
  h1, .buero-nav {
    grid-column: 1 / 3;
  }
  .buero-nav {
    align-self: end;
  }
}
@media(max-width: 699px) {
  .buero-nav {
    margin-top: 6rem;
    //padding-left: calc(var(--standard-indent) + 0.5rem);
    align-self: end;
  }
  .front-page {
    display: grid;
    grid-template-rows: 1fr 1fr 7rem;
    height: 100%;
  }
}
