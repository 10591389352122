



























































.textpage {
  grid-row: 2 / 3;
  grid-column: 1 / 3;
}
  @media(min-width: 700px) {
    .textpage.page-0 {
      grid-column: 1 / 2;
      width: calc(50vw - 1.2rem);
      margin-right: -1rem;
      padding-right: 1rem;
      box-sizing: border-box;
    }
    .project-texts {
      //min-height: 40vw;
      //height: 100%;
    }
  }
  @media(min-width: 1000px) {
    .project-texts {
      column-count: 2;
      column-gap: 1.5rem;
      column-fill: balance;
      &:not(.islong) {
        height: 100%;
        column-fill: auto;
      }
    }
  }
