









































































.competence-desc, .list-container {
  font-size: 2rem;
  line-height: 1.1;
}
.competence-desc {
  text-indent: var(--standard-indent);
}
::v-deep .single-competence .page-title.big {
    font-size: 4rem;
}
.project-list-item {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
@media(min-width: 700px) {
  .competence-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0;
  }
  .list-container {
    position: relative;
    left: 1.2rem;
  }
  .competence-desc {
  }
  .desc-inside {
    width: 85%;
  }
  .competence-desc, .list-container {
    height: calc(100vh - 7rem);
    overflow-y: auto;
    padding-bottom: 2rem;
    box-sizing: border-box;
  }
  .projectheader {
    display: none;
  }
}
@media(max-width: 699px) {
  .competence-desc, .list-container {
    text-indent: 0;
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  .projectheader {
    text-transform: uppercase;
    font-size: .8rem;
    margin-bottom: 0.5rem;
  }
}
