












































// a { color: blue; }
img {
  width: 100%;
  height: 100%;
}
video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.imagepage {
  grid-row: 2 / 3;
}
.imagepage img {
  object-fit: cover;
}
.imagepage img.plan {
  object-fit: contain;
}
.img-caption {
  position: relative;
  &.isvideo {
    overflow: hidden;
  }
}
figcaption {
  position: absolute;
  font-size: 0.85rem;
  z-index: 100;
  background-color: #fff;
  bottom: 0;
  padding-right: 5px;
}
.plancontainer figcaption {
  display: block;
  width: 100%;
  padding-top: 0.5rem;
}
@media(min-width: 700px) {
  .imagepage {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    height: 100%;
    height: calc(100vh - 6rem - 2.5rem);
    grid-gap: 0.25em;
    &.page:not(.fullbleed){
      overflow-x: hidden;
      overflow-y: hidden;
    }
    &.page-0 {
      grid-column: 1 / 2;
    }
    &.page-1 {
      grid-column: 2 / 3;
    }
    img {
      object-fit: cover;
    }
    .img-caption {
      grid-row: span 2;
    }
    &.images-4:not(.even) {
      .img-caption:nth-of-type(1) {
        grid-column: 1 / 3;
      }
      .img-caption:nth-of-type(2) {
        grid-row: 3 / 6;
      }
      .img-caption:nth-of-type(3), img:nth-of-type(4) {
        grid-row: span 1;
        //safari requires me to specify this. it's not very exact.
        height: calc((100vh - 10rem) / 4);
      }
    }
    .imagepage-text {
      grid-column: span 2;
      column-count: 2;
      column-gap: 1rem;
      column-fill: balance;
    }
    &.images-1 {
      .img-caption {
        grid-column: 1 / 3;
        grid-row: span 4;
      }
    }
    &.images-2 {
      grid-template-rows: 1fr 1fr 1fr 1fr;
      .img-caption {
        grid-column: span 4;
      }
    }
    &.images-3 {
      .img-caption:nth-of-type(1) {
        grid-column: 1 / 3;
        grid-row: span 2;
      }
    }
    &.horizontal {
      .img-caption {
        grid-column: 1 / 3;
        grid-row: span 1;
      }
    }
  }
  .fullbleed {
    grid-column: 1 / 3;
    .img-caption {
      grid-column: 1 / 3;
      grid-row: span 4;
      position: static;
    }
    figcaption {
      bottom: 1rem;
    }
    img, video {
      position: absolute;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }
}
@media(max-width: 699px) {
  .img-caption {
    object-fit: cover;
    grid-row: 1 / 3;
  }
  .imagepage {
    display: grid;
    grid-row-gap: .5rem;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
    height: calc(100vh - 7rem - 4rem);
    grid-column: 1 / 3;
  }
  .imagepage.images-2 {
    //this was REALLY hard to setup to work in safari!!
    display: flex;
    flex-direction: column;
    .img-caption {
      height: calc(50% - 0.5rem);
      margin-bottom: 0.5rem;
    }
    img {
    }
  }
  .imagepage.horizontal {
    //this was REALLY hard to setup to work in safari!!
    display: flex;
    flex-direction: column;
    .img-caption {
      margin-bottom: 0.5rem;
    }
    &.images-3 {
      height: calc(33% - 0.5rem);
    }
    //this last one isn't a thing, because 4-groups are always split up, but in case...
    &.images-4 {
      height: calc(24% - 0.5rem);
    }
  }
  .fullbleed {
    grid-column: 1 / 3;
    .img-caption {
      grid-column: 1 / 3;
      grid-row: span 4;
    }
    img, video {
      position: fixed;
      width: 100vw;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }
}
